<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_region") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="mt-2 mr-2" v-loading="loadingData">
      <div class="modal-body">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="aticler_m"
          size="small"
        >
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item :label="$t('message.region')">
                <el-select
                  filterable
                  remote
                  :remote-method="searchRegionsLoad"
                  clearable
                  placeholder="Регион"
                  size="mini"
                  v-loading="loadingData"
                  v-model="form.region_id"
                >
                  <el-option
                    v-for="item in regions"
                    :key="item.name + item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('message.city')" prop="name">
                <el-input v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
                            <el-form-item :label="columns.code.title" prop="name">
                                <el-input v-model="form.code" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col> -->
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import region from "@/components/selects/region";
import drawerChild from "@/utils/mixins/drawer-child";
import _ from "lodash";
export default {
  mixins: [form, drawerChild],
  props: ["selected"],
  components: {
    region,
  },
  data() {
    return {
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      rules: "cities/rules",
      model: "cities/model",
      columns: "cities/columns",
      regions: "regions/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "cities/update",
      editModel: "cities/show",
      loadRegions: "regions/inventory",
      empty: "cities/empty",
    }),
    afterOpened() {
      if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.editModel(this.selected.id)
          .then(async (res) => {
            this.loadingData = false;
            this.form = JSON.parse(JSON.stringify(this.model));
            this.loadRegions({ selected_id: this.form.region_id });
          })
          .catch((err) => {
            this.loadingData = false;
            this.$alert(err);
          });
      }
    },
    afterClosed() {
      this.empty();
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    searchRegionsLoad(val) {
      this.loadingData = true;
      this.searchregions(val);
    },
    searchregions: _.debounce(function (val) {
      this.loadRegions({ search: val })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    }, 500),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
